import React from "react"
import {Helmet} from "react-helmet/es/Helmet"
import favicon from '../../images/favicon.ico';

const Header = ({title}) => {
    return (
        <Helmet>
            <title>{title}</title>
            <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
            <link rel="icon" href={favicon}/>
        </Helmet>
    )
}

export default Header